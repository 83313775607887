import "vanilla-cookieconsent/src/cookieconsent";

// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
  current_lang: "cs",
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0, // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
  },

  onAccept: function (cookie) {
    // ...
  },

  onChange: function (cookie, changed_preferences) {
    // ...
  },

  gui_options: {
    consent_modal: {
      layout: "box", // box/cloud/bar
      position: "bottom left", // bottom/middle/top + left/right/center
    },
  },

  languages: {
    cs: {
      consent_modal: {
        title: "Webové stránky používají cookies!",
        description:
          'Tyto webové stránky používají cookies k zajištění provozu stránek a zjištění jejich používání. <button type="button" data-cc="c-settings" class="cc-link">Nastavit chování</button>',
        primary_btn: {
          text: "Povolit vše",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Odmítnout vše",
          role: "accept_necessary", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Nastavení cookies",
        save_settings_btn: "Uložit nastavení",
        accept_all_btn: "Povolit vše",
        reject_all_btn: "Odmítnout vše",
        close_btn_label: "Zavřít",
        cookie_table_headers: [
          { col1: "Jméno" },
          { col2: "Doména" },
          { col3: "Expirace" },
          { col4: "Popis" },
        ],
        blocks: [
          {
            title: "Použití cookies 📢",
            description:
              'Používáme cookies pro základní provoz stránek a také získání informací o jejích užívání. Pro více informací si můžete přečíst <a href="/frontend/build/zpracovani-osobnich-udaju.pdf" class="cc-link">zásady ochrany osobních údajů</a>.',
          },
          {
            title: "Nutné cookies",
            description:
              "Tyto cookies jsou nutné k základnímu fungování těchto webových stránek.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analytické cookies",
            description:
              "Tato cookies nám dovolují uložit informace o Vašich preferencích na této stránce.",
            toggle: {
              value: "analytics", // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: "^_ga", // match all cookies starting with "_ga"
                col2: "google.com",
                col3: "2 roky",
                col4: "",
                is_regex: true,
              },
              {
                col1: "_gid",
                col2: "google.com",
                col3: "1 den",
                col4: "",
              },
            ],
          },
          {
            title: "Reklamní a targetovací cookies",
            description:
              "Tyto cookies nám pomáhají pochopit, jak naši uživatelé používají tyto stránky. Všechan data, která získáváme, jsou anonymizována a nedovolují nám identifikovat jednotlivé uživatele.",
            toggle: {
              value: "targeting",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "Více informací",
            description:
              'Pro více informací nás prosím <a class="cc-link" href="/o-nas/">kontaktujte</a>.',
          },
        ],
      },
    },
  },
});
